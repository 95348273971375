<template>
  <BaseLayout>
    <ReportIndex />
  </BaseLayout>
</template>

<script>
import ReportIndex from '@/composites/report/index/Index.vue'

export default {
  name: 'Reports',

  components: {
    BaseLayout: () => import('@/views/shared/BaseLayout.vue'),
    ReportIndex,
  },
}
</script>
